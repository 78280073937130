import React, {Component} from 'react';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import {toast, ToastContainer} from "react-toastify";
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import NumberFormat from 'react-number-format';
import TablePaginationActions from "../../presentational/table-pagination-actions";
import {history} from "../../../shared/configure-store";

require('dotenv').config();

const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

toast.configure({
    autoClose: 2000,
    draggable: false,
});

class Accounts extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            loadingZipcode: false,
            errors: {},
            search: '',

            showDetail: false,
            showForm: false,
            showFormEdit: false,
            showPassword: false,
            showEdit: false,

            rows: [],
            sendPageNumber: '',
            sendPerPage: '',
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,
            totalData: 0,

            idParam: '',
            idAccount: '',
            fullName: '',
            email: '',
            password: '',
            phone: '',
            avatar: '',
            address: '',
            address2: '',
            postalCode: '',
            city: '',
            stateName: '',
            stateId: '',
            status: '',
            currId: 0,

            exFullName: '',
            exEmail: '',
            exPhone: '',
            exAvatar: '',
            exAddress: '',
            exAddress2: '',
            exZipCode: '',
            exStateName: '',
            exCity: '',
            exStateId: '',
            exStatus: '',
            transponderNumber: '',
            transponderId: '',
            activities: [],
            tickets: [],
            payments: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPressSearch = this.handleKeyPressSearch.bind(this);
        this.handleClickSearch = this.handleClickSearch.bind(this);
        this.handleShowDetail = this.handleShowDetail.bind(this);
        this.handleShowForm = this.handleShowForm.bind(this);
        this.paymentName = this.paymentName.bind(this);
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        if (id) {
            let route = '/accounts?perPage&perPageNumber&keyword=' + id;

            Api.get(route, false).then(resp => {

                if (resp.data) {

                    let data = '';

                    resp.data.users.forEach(function (o, idx) {

                        if (idx === 0) {
                            data = o;
                        }

                    });

                    if (typeof data === 'object') {
                        this.handleShowDetail(data);
                    }
                }

            }).catch(err => {
                console.log(err);
            });
        }

        this.__fetchData(false);
    }

    __fetchData = update => {
        let page = update ? parseInt(this.state.currentPage + 1) : this.state.currentPage;

        let route = '/accounts?perPage=' + this.state.perPage + '&pageNumber=' + page + '&keyword=' + this.state.search;

        let idAccount = this.state.currId;

        Api.get(route, false).then(resp => {

            if (resp.data) {

                let data = '';

                resp.data.users.forEach(function (o) {

                    if (o.id === idAccount) {
                        o.selected = true;
                        data = o;
                    } else {
                        o.selected = false;
                    }

                });

                if (typeof data === 'object') {
                    this.handleShowDetail(data);
                }

                this.setState({
                    rows: resp.data.users,
                    total: resp.data.meta.total,
                    perPage: parseInt(resp.data.meta.perPage),
                    currentPage: resp.data.meta.currentPage,
                    currentPageTable: resp.data.meta.currentPage - 1,
                    totalData: resp.data.meta.totalData,
                    loading: false,
                })


            }

        }).catch(err => {
            console.log(err);
        });
    };

    handleChange(e, prop) {

        this.setState({

            [prop]: e.target.value

        })

    };

    handleKeyPressSearch = (event) => {

        if (event.key === 'Enter') {

            this.handleClickSearch();

        }

    };

    handleClickSearch() {

        this.setState({
                loading: false,
                currentPage: 0
            },
            () => this.__fetchData(false));
    };

    handleShowDetail = (id) => {

        if (id) {
            history.push('/accounts/detail/' + id);
        }
        /* let arrObjRows = this.state.rows;

         arrObjRows.forEach(function(o){
             o.selected = o.id === data.id;
         });

          this.setState({
                  showDetail: true,
                  showForm: false,
                  showEdit: false,
                  idAccount: data.id,
                  transponderId: data.transponderId,
                  transponderNumber: data.transponderNumber,
                  exFullName: data.fullName,
                  exEmail: data.email,
                  exPhone: data.phone,
                  exAvatar: data.avatar,
                  exAddress: data.address,
                  exAddress2: data.address2,
                  exPostalCode: data.postalCode,
                  exCity: data.city,
                  exStateName: data.stateName,
                  exStatus: data.status,
                  exVehicles: data.vehicles,
                  activities: data.activities,
                  tickets: data.tickets,
                  payments: data.payments,

                  status: data.status === null ? '' : data.status,
                  rows: arrObjRows
              }
          )*/
    };

    handleShowForm = e => {
        this.setState(prevState => ({
                showDetail: false,
                showFormEdit: false,
                showForm: !prevState.showForm,
                fullName: '',
                city: '',
                stateId: '',
                stateName: '',
                address: '',
                address2: '',
                postalCode: '',
                phone: '',
                idAccount: ''
            })
        )
    };

    handleShowEdit = e => {
        e.preventDefault();

        this.setState({
            showEdit: true,
        })
    };

    handleCloseEdit = e => {
        e.preventDefault();

        this.setState({
            showEdit: false,
        })
    };

    showMessage = (status, message) => {
        if (status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        } else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }

    };

    handleUpdate = e => {
        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            status: this.state.status
        };

        Api.put('/accounts/' + this.state.transponderId, params, false).then(resp => {

            let msg;
            if (resp.success) {
                if (this.state.status === 'active') {
                    this.setState({
                            status: 'active',
                            exStatus: 'active',
                            loadingButton: false,
                            showEdit: false,
                        }, () => {
                            msg = 'activated';
                        }
                    );
                } else {
                    this.setState({
                            status: 'suspend',
                            exStatus: 'suspend',
                            loadingButton: false,
                            showEdit: false,
                        }, () => {
                            msg = 'suspended';
                        }
                    );

                }

            }

            this.__fetchData(false);

            this.showMessage(true, 'User successfully been ' + msg);

        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false,
                }
            );

            this.showMessage(false, 'Invalid format data');

        });

    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage: newPage,
            currentPageTable: newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage: parseInt(event.target.value, 10),
            currentPage: 1,
            loading: true,
        }, () => {
            this.__fetchData(false);
        });
    };


    paymentName = name => {
        switch (name) {
            case "googlePay":
                name = "Google Pay";
                break;
            case "applePay":
                name = "Apple Pay";
                break;
            case "creditCard":
                name = "Credit Card";
                break;
            case "visa":
                name = "Visa";
                break;
            case "mastercard":
                name = "Mastercard";
                break;
            case "americanExpress":
                name = "American Express";
                break;
            case "discover":
                name = "Discover";
                break;
            default:
                name = "I have never heard of that fruit...";
        }

        return name;
    };


    render() {
        return (
            <div className="row">
                <div className="col-md-8 col-lg-10">
                    <div className="row">
                        <div className="col search-bar">
                            <TextField
                                type="text"
                                id="adornment-search"
                                name="search"
                                placeholder="Search by name or account number"
                                onChange={(e) => this.handleChange(e, 'search')}
                                onKeyPress={this.handleKeyPressSearch}
                                value={this.state.search}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickSearch}
                                            >
                                                <i className="fas fa-search"> </i>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                        </div>
                    </div>
                    <div className="table-wrapper">
                        <Table className="table-list mt-3" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Account Number</TableCell>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Phone Number</TableCell>
                                    <TableCell>Toll Tag</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.state.loading ? (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center" className="py-5">
                                            <CircularProgress color="primary"/>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.rows.length === 0 ? (
                                        <TableRow style={{height: 33 * this.state.perPage}}>
                                            <TableCell colSpan={5} style={{textAlign: "center"}}>No accounts</TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.rows.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                onClick={() => this.handleShowDetail(row.id)}
                                                selected={row.selected}
                                            >
                                                <TableCell>
                                                    <strong>{row.id} </strong>
                                                    {/* {
                                                    row.id &&
                                                    <NumberFormat className="title mb-0 numb" value={row.id.substring(4,15)} displayType={'text'} format="##### ##### #" />
                                                }*/}
                                                </TableCell>
                                                <TableCell
                                                    className="text-capitalize">{row.firstName === null ? 'NA' : row.firstName}</TableCell>
                                                <TableCell
                                                    className="text-capitalize">{row.lastName === null ? 'NA' : row.lastName}</TableCell>
                                                <TableCell>{row.email === null ? 'NA' : row.email}</TableCell>
                                                <TableCell>{row.phone === null ? 'NA' : row.phone}</TableCell>
                                                {
                                                    row.transponders.length > 0 ? (
                                                        parseInt(row.transponders[0].number) ? (
                                                            <TableCell>{row.transponders[0].number}</TableCell>
                                                        ) : (
                                                            <TableCell>NA</TableCell>
                                                        )
                                                    ) : (
                                                        <TableCell>NA</TableCell>
                                                    )
                                                }
                                            </TableRow>
                                        ))
                                    )
                                )}

                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={this.state.totalData}
                        rowsPerPage={this.state.perPage}
                        page={this.state.currentPageTable}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </div>
                <div className="col-md-6 col-lg-7 content-right">
                    <Paper className={this.state.showDetail ? "show" : "hide"}>
                        <div className="row">
                            <div className="col-12">
                                <h3>{this.state.exFullName}</h3>
                            </div>
                            <div className="col-md-7">
                                <p className="title mb-0">
                                    {
                                        this.state.transponderNumber &&
                                        <NumberFormat className="title mb-0 numb" value={this.state.transponderNumber}
                                                      displayType={'text'} format="#### ##### ##### #"/>
                                    }
                                </p>
                                {/*<p className="title mb-0">Account {
                                        this.state.transponderNumber &&
                                        this.state.transponderNumber.match(/.{1,4}/g).map((obj, idx) => {
                                            return (idx === 0 ?
                                                    (<strong key={idx}>{obj} </strong>) :
                                                    (<span key={idx}>{obj} </span>)
                                            )
                                        })
                                    }</p>*/}
                                <fieldset className="form-group">
                                    <div className="row">
                                        <legend
                                            className="col-form-label col-sm-3 col-md-12 col-lg-6 col-xl-3 pt-sm-0">Status:
                                        </legend>
                                        <div className="col-sm-6 pl-lg-0">
                                            {
                                                this.state.showEdit ?
                                                    (
                                                        <div>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="status"
                                                                    onChange={(e) => this.handleChange(e, 'status')}
                                                                    id="statusActive"
                                                                    value="active"
                                                                    checked={this.state.status === 'active' && 'checked'}
                                                                />
                                                                <label className="form-check-label"
                                                                       htmlFor="statusActive">
                                                                    Active
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="status"
                                                                    onChange={(e) => this.handleChange(e, 'status')}
                                                                    id="statusSuspend"
                                                                    value="suspend"
                                                                    checked={this.state.status === 'suspend' && 'checked'}
                                                                />
                                                                <label className="form-check-label"
                                                                       htmlFor="statusSuspend">
                                                                    Suspended
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ) :
                                                    (
                                                        <p className="text-capitalize mb-0">{this.state.exStatus}</p>
                                                    )
                                            }
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div className="col-md-5">
                                <p className="title mb-0">Contact</p>
                                <p style={{wordBreak: 'break-word'}}>
                                    {this.state.exEmail}<br className={this.state.exEmail === null ? 'd-none' : ''}/>
                                    {this.state.exPhone}</p>
                            </div>
                            <div className="col-md-7">
                                <p className="title mb-0">Address</p>
                                <p className="mb-0">{this.state.exAddress}</p>
                                <p className="mb-0">{this.state.exAddress2}</p>
                                <p className="mb-0">{this.state.exCity}{this.state.exStateName && ', ' + this.state.exStateName}</p>
                                <p>{this.state.exPostalCode}</p>
                            </div>
                            <div className="col-md-5">
                                <p className="title mb-0">Tickets</p>
                                {
                                    this.state.tickets &&
                                    this.state.tickets.map(item => {
                                        return (
                                            <div key={item.id}>
                                                <p className="mb-0">Item {item.ticketNumber}</p>
                                                <p>{item.content}</p>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                            <div className="col-md-7">
                                <p className="title mb-0">Vehicle</p>
                                {
                                    this.state.exVehicles &&
                                    this.state.exVehicles.map((row) => {
                                        return (
                                            <p key={row.id}>
                                                {row.licensePlateNumber}<br/>
                                                {row.licensePlateState}<br/>
                                                {row.nickname}<br/>
                                            </p>
                                        )
                                    })
                                }

                            </div>
                            <div className="col-md-5">
                                <p className="title mb-0">Payment methods</p>
                                {
                                    this.state.payments &&
                                    this.state.payments.map(item => {
                                        return (
                                            <div key={item.id}>
                                                <p className="mb-0">{this.paymentName(item.paymentMethods)} {item.paymentTypes && '- ' + this.paymentName(item.paymentTypes)} </p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <p className="title mb-0">Activity</p>
                            </div>
                            <div className="card-activity mb-3">
                                {
                                    this.state.activities.length ?
                                        this.state.activities.map(item => {
                                            return (
                                                <div key={item.id} className="row">
                                                    <div className="col-md-7">
                                                        <p className="mb-0">{item.transactionTypes}</p>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <p className="mb-0">${item.amount} ({item.status})</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        (
                                            <div className="row">
                                                <div className="col-12 text-center">
                                                    <p className="mb-0">No transaction</p>
                                                </div>
                                            </div>
                                        )
                                }


                            </div>

                        </div>
                        {
                            this.state.showEdit ?
                                (
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <button className="btn btn-blue-trans"
                                                    onClick={this.handleCloseEdit}>Cancel
                                            </button>
                                        </div>
                                        <div className="col text-right">
                                            <button
                                                className="btn btn-blue-trans"
                                                onClick={this.handleUpdate}
                                                disabled={this.state.loadingButton && 'disabled'}>
                                                Save Changes {this.state.loadingButton &&
                                            <i className="fa fa-spinner fa-spin"> </i>}</button>
                                        </div>
                                    </div>
                                ) :
                                (
                                    this.state.transponderId &&
                                    <button className="btn btn-blue-trans" onClick={this.handleShowEdit}>Edit</button>
                                )
                        }
                    </Paper>
                </div>
                <ToastContainer/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);