import React, {Component} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import PaymentRulesUpdate from "./updateRules";
import Api from "../../../libraries/api";
import PaymentRulesList from "./list";

class PaymentRules extends Component {

    constructor(props) {
        super(props);
        this.state = {
            paymentRules: [],
            showForm: false,
            itemUpdate: []
        };
        this.getPaymentRules()
        this.getPaymentRules = this.getPaymentRules.bind(this);
        this.showUpdatePayForm = this.showUpdatePayForm.bind(this);
        this.hideUpdatePayForm = this.hideUpdatePayForm.bind(this);
    }

    getPaymentRules() {
        let paymentRulesConfigUrl = "/payment-rules";
        Api.get(paymentRulesConfigUrl, [], false).then((resp) => {
            this.setState({paymentRules: resp["data"]["paymentRules"]});
        });
    }

    showUpdatePayForm(id) {
        let paymentRulesConfigUrl = "/payment-rules/id/" + id;
        Api.get(paymentRulesConfigUrl, [], false).then((resp) => {
            this.setState({showForm: true, itemUpdate: resp["data"]["paymentRules"]})
        });

    }

    hideUpdatePayForm() {
        this.setState({showForm: false, itemUpdate: []})
    }

    render() {
        return (
            <div>
                <h1>Payment Rules</h1>
                <p>Manage the charge fee for many products, transactions or operations.</p>
                <div>
                    <PaymentRulesUpdate showForm={this.state.showForm} item={this.state.itemUpdate}
                                        updateTable={this.getPaymentRules} hideForm={this.hideUpdatePayForm}/>
                    <div className="table-wrapper">
                        <Table className="table-list mt-3" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Product</TableCell>
                                    <TableCell>Alias</TableCell>
                                    <TableCell>Fee ($)</TableCell>
                                    <TableCell>Fee Avg (%)</TableCell>
                                    <TableCell>Transaction limit</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <PaymentRulesList paymentRules={this.state.paymentRules} handler={this.showUpdatePayForm}/>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default PaymentRules;