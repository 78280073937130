import React, {Component} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";

export default class PaymentRulesList extends Component {

    render() {
        let updateFormHandler = this.props.handler
        return (
            <TableBody>
                {this.props.paymentRules.map(function (item, i) {
                    return (
                        <PayRuleRow key={i} id={item.id} item={item} formHandler={updateFormHandler}/>
                    );
                })}
            </TableBody>
        );
    }
}

class PayRuleRow extends Component {

    render() {
        return (
            <TableRow onClick={() => this.props.formHandler(this.props.id)}>
                <TableCell>
                    {this.props.item.product}
                </TableCell>
                <TableCell>
                    {this.props.item.alias}
                </TableCell>
                <TableCell>
                    $ {this.props.item.fee}
                </TableCell>
                <TableCell>
                    % {this.props.item.feeAvg}
                </TableCell>
                <TableCell>
                    {this.props.item.transactionLimit}
                </TableCell>
                <TableCell>
                    {this.props.item.status === 1 ? 'Enable' : 'Disable'}
                </TableCell>
            </TableRow>
        )
    }
}