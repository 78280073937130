import React, {Component} from "react";
import {toast} from "react-toastify";
import Api from "../../../libraries/api";

export default class UpdateAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updateAction: false,
        };
        this.handleClick = this.handleClick.bind(this);
        this.saveAccount = this.saveAccount.bind(this);
    }

    handleClick() {
        this.setState({updateAction: true})
    }

    saveAccount(e) {
        e.preventDefault()
        const updateAccountUrl = "/users/" + this.props.userId;
        let parameters = {
            status: document.getElementById("accountStatus").value,
            accountVersion: document.getElementById("accountVersion").value
        };
        Api.put(updateAccountUrl, parameters, false).then((resp) => {
            this.setState({status: parameters["status"], accountVersion: parameters["accountVersion"]})
            toast.success(resp.messages, {
                position: toast.POSITION.BOTTOM_CENTER,
            })
        }).catch(err => {
                let i;
                let errorMsg = "";
                console.log(err)
                const erroList = Object.keys(err.errors);
                for (i = 0; i < erroList.length; i++) {
                    const index = erroList[i].toString();
                    errorMsg = errorMsg + err.errors[index]
                }
                toast.error(errorMsg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                })
            }
        );
        this.setState({updateAction: false})
    }

    render() {
        let accountVersion = (this.state.accountVersion == null) ? this.props.accountVersion : this.state.accountVersion
        let status = (this.state.status == null) ? this.props.accountStatus : this.state.status
        if (this.state.updateAction === true) {//Update user account
            return <div>
                <form
                    id="frmUpdateAccount"
                    name="frmUpdateAccount"
                    method="Post"
                    onSubmit={this.saveAccount}
                >
                    <label className="title">Status</label>
                    <p className="pl-2 pl-lg-3" className="capitalize-text">
                        <AccountStatusItems statusItem={this.state.status}/>
                    </p>
                    <label className="title">Account version</label>
                    <p className="pl-2 pl-lg-3" className="capitalize-text">
                        <AccountVersionItems accountVersionItem={this.state.accountVersion}/>
                    </p>
                    <br/>
                    <input type="submit" value="Update account" className="btn btn-blue-trans mb-2"/>
                </form>
            </div>;
        } else {//Show user account information
            return <div>
                <label className="title">Status</label>
                <p className="pl-2 pl-lg-3" className="capitalize-text">
                    {status}
                </p>
                <label className="title">Account version</label>
                <p className="pl-2 pl-lg-3" className="capitalize-text">
                    {accountVersion}
                </p>
                <br/>
                <button onClick={this.handleClick} className="btn btn-blue-trans mb-2">Update account</button>
            </div>;
        }

    }
}

class AccountVersionItems extends Component {
    accountVersionData = [
        {value: 'lite', name: 'Lite'},
        {value: 'premium', name: 'Premium'}
    ];

    render() {
        return <select id="accountVersion" name="accountVersion" defaultValue={this.props.accountVersionItem}>
            {this.accountVersionData.map((e, key) => {
                return <option key={key} value={e.value}>{e.name}</option>;
            })}
        </select>
    }

}

class AccountStatusItems extends Component {
    accountStatusData = [
        {value: 'active', name: 'Active'},
        {value: 'suspend', name: 'Suspend'},
        {value: 'disable', name: 'Disable'}
    ];

    render() {
        return <select id="accountStatus" name="accountStatus" defaultValue={this.props.statusItem}>
            {this.accountStatusData.map((e, key) => {
                return <option key={key} value={e.value}>{e.name}</option>;
            })}
        </select>
    }

}