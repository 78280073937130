import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { RootActions } from "../../../shared/root-action";
import { connect } from "react-redux";
import AuthHelper from "../../../libraries/auth-helper";

class Sidebar extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  handleLogout = () => {
    AuthHelper.logOut();
  };

  render() {
    const { location } = this.props;
    const perms = this.props.permission_state;

    return (
      <aside
        className={
          "side-nav " + (this.props.toggle_sidebar_state ? "show" : "")
        }
      >
        <ul className="nav flex-column">
          <li className="nav-item name d-md-none">
            Welcome, {this.props.profile_state.fullName}
          </li>
          <li className={"nav-item " + (location.pathname === "/" && "active")}>
            <Link to="/">Home</Link>
          </li>
          {perms.includes("accounts") && (
            <li
              className={
                location.pathname === "/accounts"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link to="/accounts">Accounts</Link>
            </li>
          )}

          <li
            className={
              location.pathname === "/fulfillment"
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link to="/fulfillment">Fulfillment</Link>
          </li>
          <li
            className={
              location.pathname === "/sales" ? "nav-item active" : "nav-item"
            }
          >
            <Link to="/sales">Orders</Link>
          </li>
          <li
            className={
              location.pathname === "/tickets" ? "nav-item active" : "nav-item"
            }
          >
            <Link to="/tickets">Tickets</Link>
          </li>
          <li
            className={
              location.pathname === "/transactions"
                ? "nav-item active"
                : "nav-item"
            }
          >
            <Link to="/transactions">Transactions</Link>
          </li>
          <li
            className={
              location.pathname === "/modules" ? "nav-item active" : "nav-item"
            }
          >
            <Link to="/modules">Modules</Link>
          </li>
          <li
              className={
                location.pathname === "/payment-rules" ? "nav-item active" : "nav-item"
              }
          >
            <Link to="/payment-rules">Pay rules</Link>
          </li>
          {perms.includes("users") && (
            <li
              className={
                location.pathname === "/access-management"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link to="/access-management">Access Mgmt</Link>
            </li>
          )}

          <li
            className={
              location.pathname === "/settings" ? "nav-item active" : "nav-item"
            }
          >
            <Link to="/settings">Settings</Link>
          </li>
          <li
            className={
              location.pathname === "/info" ? "nav-item active" : "nav-item"
            }
          >
            <Link to="/info">Info/ KB</Link>
          </li>
          <li
            className={
              location.pathname === "/" ? "nav-item active" : "nav-item"
            }
          >
            <button onClick={this.handleLogout}>Sign out</button>
          </li>
        </ul>
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    toggle_sidebar_state: state.toggle_sidebar_state,

    profile_state: state.profile_state,

    permission_state: state.permission_state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(RootActions, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
