import React, {Component} from "react";
import Api from "../../../libraries/api";
import TextField from "@material-ui/core/TextField";
import {toast} from "react-toastify";

export default class PaymentRulesUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formPaymentRules: null,
        };
        this.savePaymentRule = this.savePaymentRule.bind(this);
    }

    savePaymentRule(e) {
        e.preventDefault()
        const updatePayRuleUrl = "/payment-rules/id/" + this.props.item.id;
        let parameters = {
            product: this.props.item.product,
            fee: this.props.item.fee,
            feeAvg: this.props.item.feeAvg,
            transactionLimit: this.props.item.transactionLimit
        };
        Api.put(updatePayRuleUrl, parameters, false).then((resp) => {
            this.props.updateTable()
            this.props.hideForm()
            toast.success(resp.messages, {
                position: toast.POSITION.BOTTOM_CENTER,
            })
        }).catch(err => {
                let i;
                let errorMsg = "";
                const erroList = Object.keys(err.errors);
                for (i = 0; i < erroList.length; i++) {
                    const index = erroList[i].toString();
                    errorMsg = errorMsg + err.errors[index]
                }
                toast.error(errorMsg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
            }
        );
    }

    handleChange(e, propData) {
        this.props.item[propData] = e.target.value
    };

    render() {
        if (this.props.showForm === true) {//Update payment rule
            return (
                <div className="MuiPaper-root MuiPaper-elevation1 show MuiPaper-rounded">
                    <form id="frmPayRuleUpdate" name="frmPayRuleUpdate" method="Post" onSubmit={this.savePaymentRule}>
                        <div className="form-group row">
                            <div className="col-sm-4 pl-sm-0">
                                <TextField type='text' name="product" id="product" label="Product"
                                           defaultValue={this.props.item.product}
                                           onChange={(e) => this.handleChange(e, 'product')}/>
                            </div>
                            <div className="col-sm-4 pl-sm-0">
                                <TextField type='text' name="fee" id="fee" label="Fee"
                                           defaultValue={this.props.item.fee}
                                           onChange={(e) => this.handleChange(e, 'fee')}/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-4 pl-sm-0">
                                <TextField type='text' name="feeAvg" id="feeAvg" label="Fee Avg(%$)"
                                           defaultValue={this.props.item.feeAvg}
                                           onChange={(e) => this.handleChange(e, 'feeAvg')}/>
                            </div>
                            <div className="col-sm-4 pl-sm-0">
                                <TextField type='text' name="transactionLimit" id="transactionLimit"
                                           label="Transactional limit"
                                           defaultValue={this.props.item.transactionLimit}
                                           onChange={(e) => this.handleChange(e, 'transactionLimit')}/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <input type="submit" value="Save" className="btn btn-blue-trans"/>
                        </div>
                    </form>
                    <br/>
                </div>
            )
        }
        return false
    }
}