import React,{Component} from 'react';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import { Link } from 'react-router-dom';
import {toast, ToastContainer} from "react-toastify";
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MomentUtils from '@date-io/moment';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";

require('dotenv').config();

const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

toast.configure({
    autoClose: 2000,
    draggable: false,
});

class SaleLists extends Component {
    constructor(props) {

        super(props);
        this.state = {
            firstTime: true,
            loading: true,
            loadingButton: false,
            loadingZipcode: false,
            errors: {},
            search: '',
            start_date: null,
            end_date: null,
            list_assignee:[],
            list_status: [
                'Ordered',
                'Send',
                'Delivered',
                'Canceled'
            ],
            s_assignee:'',
            s_status:'',

            showDetail: false,

            rows: [],
            sendPageNumber: '',
            sendPerPage: '',
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,
            totalData: 0,

            idUser: this.props.user && this.props.user,
            id: '',
            created:'',
            ticketNumber: '',
            accountId: '',
            fullName:'',
            contact:'',
            email: '',
            password: '',
            phone: '',
            avatar: '',
            address: '',
            address2: '',
            postalCode: '',
            city: '',
            stateName: '',
            stateId: '',
           
            deliveryProvider: [],
            user: {},
            room: {},
            messages: {},
            typing: {},

            userId: '',
            addressId:'',
            transponderPriceId:'',
            transponderId:'',
            deliveryProviderId:'',
            trackId:'',
            status: '',
            notifyStatus:'',
            orderType: this.props.OrderType,

        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.handleKeyPressSearch = this.handleKeyPressSearch.bind(this);
        this.handleClickSearch = this.handleClickSearch.bind(this);
        this.handleShowDetail = this.handleShowDetail.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {


       

        // if(this.state.idUser !==  undefined) {
        //     this.__fetchDataUser(false);
        // }else {
        //     this.__fetchData(false);
        // }

        // Api.get('/purchases', false).then(resp => {

        //     if (resp.data) {

        //         this.setState({
        //             list_assignee: resp.data.Purchases
        //         });

        //     }

        // }).catch(err => {
        //     console.log(err);
        // });


        Api.get('/delivery/provider', true).then(resp => {
            if(resp.data) {
                console.log(resp.data);
               this.setState({
                deliveryProvider:resp.data.deliveryProvider,
               });
            }
        }).catch(err => {
            console.log(err);
        });

        this.__fetchData(false);
    } 
    



    __fetchData = update => {
        //let page = update ? parseInt(this.state.currentPage + 1) : this.state.currentPage;

        //let keyword = this.state.search === '' ? '' : '&keyword=' + this.state.search;

        //let start_date = this.state.start_date === null ? '' : '&start=' + moment(this.state.start_date).format('YYYY-MM-DD');
        //let end_date = this.state.end_date === null ? '' : '&end=' + moment(this.state.end_date).format('YYYY-MM-DD');
        //let s_assignee = this.state.s_assignee === '' ? '' : '&assignee=' + this.state.s_assignee;
        //let s_status = this.state.s_status === '' ? '' : '&status=' + this.state.s_status;

        //let searchById = this.state.idUser ? '/' + this.state.idUser : '';

        //let route = '/purchases?perPage=' + this.state.perPage + '&pageNumber=' + page + keyword + start_date + end_date + s_assignee + s_status;

        let route = '/purchase/type/'+ this.state.orderType;

        console.log(route);
        
        Api.get(route, false).then(resp => {

            if (resp.data) {

                this.setState({
                    loading: false,
                    rows: resp.data.Purchases,
                    //total: 0,//resp.data.meta.total,
                    //perPage: parseInt(resp.data.meta.perPage),
                    //currentPage: resp.data.meta.currentPage,
                    //currentPageTable: resp.data.meta.currentPage - 1,
                    //totalData: resp.data.meta.totalData,
                });

            }

        }).catch(err => {
            console.log(err);
        });
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value
        })

    };
    handleChangeSearch (e, prop){

        let val;

        if(prop === 'start_date' || prop === 'end_date') {
            val = e;
        }else{
            val = e.target.value
        }

        this.setState({

            [prop]: val
        }, () => this.handleClickSearch());

    };

    handleSentOrder = e => {
        
        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );
            return false;
        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            userId: this.state.userId,
            addressId: this.state.addressId,
            transponderId: this.state.transponderId,
            transponderPriceId: this.state.transponderPriceId,
            deliveryProviderId: this.state.deliveryProviderId,
            trackId: this.state.trackId,
            created: this.state.created,
            status: 'sent',
            notifyStatus: 'pending',
           
        };
 
        Api.put('/purchase/'+this.state.id, params, false).then(resp => {
       
             this.setState({
                userId: '',
                addressId: '',
                transponderId: '',
                deliveryProviderId: '',
                trackId: '',
                created: '',
                status: '',
                currentPage:1,
                currentPageTable:0,
                transponderPriceId:'',
                loadingButton: false,
                showDetail: false,
                })
           
            this.__fetchData(false);

            this.showMessage(true, 'Purchase successfully updated');

        }).catch(err => {

            this.setState({
                errors: err.errors,
                loadingButton: false,
                showDetail: false,
                }
            );

            this.showMessage(false, 'Invalid format data');

        });
    };




    handleKeyPressSearch = (event) => {

        if(event.key === 'Enter'){

            this.handleClickSearch();

        }

    };

    handleClickSearch(){

        this.setState({
            loading: true,
        });

        this.__fetchData(false);

    };

    handleShowDetail = (data) => {

        let arrObjRows = this.state.rows;

        arrObjRows.forEach(function(o){
            o.selected = o.id === data.id;
        });

        let route = '/accounts/'+data.userId;
        Api.get(route).then(resp => {

            if (resp.data) {

                let data = resp.data.users;

                this.setState({
                    fullName: data.name,
                    contact: data.contact,
                    address: data.address,
                    stateName: data.stateName,
                    city: data.city,
                    postalCode: data.postalCode
                })
            }

        }).catch(err => {
            console.log(err);
        });

        this.setState({
                showDetail: true,
                id: data.id,
                created: data.created,
                transponderPriceId: data.transponderPriceId,
                status: data.status,
                addressId:data.addressId,
                userId: data.userId
            }
        );
    };


    handleClose = e => {
        this.setState(prevState => ({
                showDetail: false,
            })
        )
    };

    handleShowEdit = e => {
        e.preventDefault();

        this.setState({
            showEdit: true,
        })
    };

    handleCloseEdit = e => {
        e.preventDefault();

        this.setState({
            showEdit: false,
        })
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }

    };

    handleUpdate = e => {
        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );


        this.showMessage(true, 'Success');

    };



    handleUpdateOrder = e => {
        e.preventDefault();

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            userId: this.state.userId,
            addressId:this.state.addressId,
            transponderPriceId:this.state.transponderPriceId,
            transponderId:this.state.transponderId,
            deliveryProviderId:this.state.deliveryProviderId,
            trackId:this.state.trackId,
            status: this.state.status,
        };

        Api.put('/purchase/'+this.state.id, params, false).then(resp => {

            this.setState({
                    showDetail: true,
                    showFormEdit: false,
                    userId: this.state.email,
                    addressId:'',
                    transponderPriceId:'',
                    transponderId:'',
                    deliveryProviderId:'',
                    trackId:'',
                    status: '',
                    currentPage:1,
                    currentPageTable:0,
                    loadingButton: false
                }
            );

            this.__fetchData(false);

            this.showMessage(true, 'User successfully updated');

        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );

            this.showMessage(false, 'Invalid format data');

        });
    };


    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value, 10),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };


    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="row align-items-center">
                        <div className="col-md-4 search-bar">
                            <TextField
                                type="text"
                                id="adornment-search"
                                name="search"
                                placeholder= {this.state.orderType}
                                onChange={(e) => this.handleChange(e,'search')}
                                onKeyPress={this.handleKeyPressSearch}
                                value={this.state.search}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                aria-label="Search button"
                                                onClick={this.handleClickSearch}
                                            >
                                                <i className="fas fa-search"> </i>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                        </div>
                        <div className="col-md-2 filter">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    variant="inline"
                                    margin="normal"
                                    id="start_date"
                                    label="Date"
                                    format={"MM/DD/YYYY"}
                                    value={this.state.start_date}
                                    onChange={(date) => this.handleChangeSearch(date, 'start_date')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className="table-wrapper">
                        <Table className="table-list mt-3" size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Order</TableCell>
                                    <TableCell>Request Date</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Contact</TableCell>
                                    <TableCell>Zip Code</TableCell>
                                   {/*  <TableCell><img src={Icon} alt="icon" /></TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Assignee</TableCell>
                                    <TableCell>Content</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.state.loading ? (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center" className="py-5">
                                            <CircularProgress color="primary"  />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.rows.length === 0 ? (
                                        <TableRow style={{ height: 33 * this.state.perPage}}>
                                            <TableCell colSpan={8} style={{ textAlign: "center" }}>No tickets</TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.rows.map((row, index) => {

                                            return (
                                                <TableRow
                                                    key={row.id}
                                                    onClick={() => {
                                                        this.handleShowDetail(row);
                                                    }}
                                                    selected={row.selected}
                                                >
                                                    <TableCell>{row.id === null ? 'NA' : row.id}</TableCell>
                                                    <TableCell>{row.created === null ? 'NA' : row.created}</TableCell>
                                                    <TableCell>{row.status === null ? 'NA' : row.status}</TableCell>
                                                    <TableCell>{row.userName === null ? 'NA' : row.userName}</TableCell>
                                                    <TableCell>{row.postalCode === null ? 'NA' : row.postalCode}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    )
                                )}

                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={this.state.totalData}
                        rowsPerPage={this.state.perPage}
                        page={this.state.currentPageTable}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </div>
                <Dialog
                    maxWidth={'md'}
                    open={this.state.showDetail}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    className="dialog-custom" >
                    <DialogTitle id="form-dialog-title">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <span>Order {this.state.ticketNumber}</span>
                            </div>
                            <div className="col-md-6">
                                <p className="mb-0"><label className="title d-inline">Status</label> <i className="status text-capitalize">{this.state.status}</i></p>
                            </div>
                        </div>
                        <button onClick={this.handleClose} className="btn-close">
                            <i className="fas fa-times"> </i>
                        </button>
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="title">Account number</label>
                                    <p className="pl-2 pl-lg-3">{
                                        this.state.userId
                                        /*this.state.transponderNumber &&
                                        <NumberFormat className="title mb-0 numb" value={this.state.transponderNumber} displayType={'text'} format="#### ##### ##### #" />*/
                                    }</p>
                                    <Link to={'/accounts/detail/'+this.state.userId} className="btn btn-blue-trans mb-3" >Go to Account</Link>

                                    <label className="title">Contact</label>
                                    <p className="pl-2 pl-lg-3">
                                        {this.state.fullName}<br/>
                                        {this.state.contact}<br/>
                                    </p>

                                    <label className="title">Shipping Address</label>
                                    <p className="pl-2 pl-lg-3">
                                        {this.state.stateName}, {this.state.city}<br/>
                                        {this.state.postalCode}, {this.state.address}<br/>                                   
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="title">Date created</label>
                                            <p className="pl-2 pl-lg-3">{this.state.created}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="title">Transponder Fee</label>
                                            <p className="pl-2 pl-lg-3">$0.0</p>
                                            {/* <p className="pl-2 pl-lg-3">{this.state.transponderPriceId}</p> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                        <TextField
                                                select
                                                id="deliveryProviderId"
                                                name="deliveryProviderId"
                                                label="Delivery Provider"
                                                onChange={(e) => this.handleChange(e, 'deliveryProviderId')}
                                                minLength={4}
                                                maxLength={50}
                                                value={this.state.deliveryProviderId}
                                                fullWidth
                                            >
                                            {this.state.deliveryProvider.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.provider}
                                                </MenuItem>
                                            ))}
                                            </TextField>
                                            {validator.message('deliveryProviderId', this.state.deliveryProviderId, 'required')}
                                            <div className='text-danger'>{this.state.errors.deliveryProviderId}</div>
                                        </div>
                                        <div className="col-md-6">
                                        <TextField
                                                type='text'
                                                id='trackId'
                                                name="trackId"
                                                label="Tracking Number"
                                                onChange={(e) => this.handleChange(e, 'trackId')}
                                                minLength={4}
                                                maxLength={50}
                                                value={this.state.trackId}
                                                fullWidth
                                            />
                                            {validator.message('trackId', this.state.trackId, 'trackId|required')}
                                            <div className='text-danger'>{this.state.errors.trackId}</div>
                                        </div>
                                        

                                        <div className="col-12">
                                            <label className="title"></label>
                                            <p className="pl-2 pl-lg-3"></p>
                                        </div>
                                        

                                        
                                        <div className="col text-right">
                                            <button
                                                className="btn btn-blue-trans"
                                                onClick={this.handleSentOrder}
                                                disabled={this.state.loadingButton && 'disabled' }>
                                                Send Transponder{ this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </Dialog>
                <ToastContainer />

            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(SaleLists);