import React,{Component}from 'react';
import TextField from "@material-ui/core/TextField";
import Paper from '@material-ui/core/Paper';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import {toast, ToastContainer} from "react-toastify";
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import AuthHelper from "../../../libraries/auth-helper";

require('dotenv').config();

const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});
const validatorEdit = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

toast.configure({
    autoClose: 2000,
    draggable: false,
});

class Transponder extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},
            search: '',

            roles: [],
            showDetail: false,
            showForm: false,
            showFormEdit: false,
            showEdit:false,

            rows: [],
            sendPageNumber: '',
            sendPerPage: '',
            total: 0,
            perPage: 10,
            currentPage: 1,
            currentPageTable: 0,
            totalData: 0,

            
            id: '',
            price: '',
            discountFullRegister: '',
            exPrice: '',
            exDiscountFullRegister: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPressSearch = this.handleKeyPressSearch.bind(this);
        this.handleClickSearch = this.handleClickSearch.bind(this);
        this.handleShowDetail = this.handleShowDetail.bind(this);
        this.handleShowForm = this.handleShowForm.bind(this);
    }

    componentDidMount() {
        Api.get('/transponder/price', true).then(resp => {
            if(resp.data) {
               this.setState({
                   roles:resp.data.deliveryProvider,
               })
            }
        }).catch(err => {
            console.log(err);
        });

        this.__fetchData(false);
    }

    __fetchData = update => {
        
        Api.get('/transponder/price', false).then(resp => {

            if (resp.data) {
                console.log(resp.data.TransponderPrice);
                
                let data = '';
                resp.data.TransponderPrice.forEach(function(o){
                    o.selected = true;
                    data =o;
                });

                if(typeof data === 'object'){
                    this.handleShowDetail(data);
                }
            }

        }).catch(err => {
            console.log(err);
        });
    };

    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        })

    };

    handleKeyPressSearch = (event) => {

        if(event.key === 'Enter'){

            this.handleClickSearch();
        }
    };

    handleClickSearch(){

        this.setState({
            loading: false,
        });

        this.__fetchData(false);

    };

    handleShowDetail = data => {

        let arrObjRows = this.state.rows;

        arrObjRows.forEach(function(o){
            o.selected = o.id === data.id;
        });

        this.setState({
            showEdit: false,
            showDetail: true,
            showForm: false,
            showFormEdit: false,
            id: data.id,
            price: data.price,
            discountFullRegister: data.discountFullRegister,
            rows: arrObjRows
            }
        );
    };

    handleShowForm = e => {
        e.preventDefault();
        this.setState(prevState => ({
                showDetail: false,
                showFormEdit: false,
                showForm: !prevState.showForm,
                price: '',
                discountFullRegister: '',
                id: ''
        })
        )
    };

    handleShowEdit = e => {
        e.preventDefault();

        this.setState({
            showEdit: true,
        })
    };

    handleCloseEdit = e => {
        e.preventDefault();

        this.setState({
            showEdit: false,
        })
    };

    showMessage = (status, message) => {
        if(status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }

    };

    handleSubmit = e => {
        

        e.preventDefault();

        if (!validator.allValid()) {

            this.setState({
                    errors: validator.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            price: this.state.price,
            discountFullRegister: this.state.discountFullRegister,
        };


        Api.delete('/transponder/price/'+this.state.id, params, false).then(resp => {
        })
            
        Api.post('/transponder/price', params, false).then(resp => {

            this.setState({
                discountFullRegister: '',
                price: '',
                currentPage:1,
                currentPageTable:0,
                loadingButton: false
                }
            );

            this.__fetchData(false);

            this.showMessage(true, 'Transponder price successfully updated');

        }).catch(err => {

            this.setState({
                errors: err.errors,
                loadingButton: false
                }
            );

            this.showMessage(false, 'Invalid format data');

        });
    };

    handleUpdate = e => {
        e.preventDefault();

        if (!validatorEdit.allValid()) {

            this.setState({
                    errors: validatorEdit.getErrorMessages()
                }
            );

            return false;

        }

        this.setState({
                errors: {},
                loadingButton: true,
            }
        );

        let params = {
            price: this.state.price,
            discountFullRegister: this.state.discountFullRegister,    
        };

        Api.put('/transponder/price/'+this.state.id, params, false).then(resp => {

            let profile = AuthHelper.getProfile();

            if(profile.id === this.state.idUser) {
                profile.fullName = this.state.fullName;
                AuthHelper.setProfile(profile);
                this.props.setProfile(profile);
            }

            this.setState({
                    showDetail: true,
                    showFormEdit: false,
                    price: '',
                    discountFullRegister: '',
                    currentPage:1,
                    currentPageTable:0,
                    loadingButton: false
                }
            );

            this.__fetchData(false);

            this.showMessage(true, 'User successfully updated');

        }).catch(err => {

            this.setState({
                    errors: err.errors,
                    loadingButton: false
                }
            );

            this.showMessage(false, 'Invalid format data');

        });
    };


    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage:newPage,
            currentPageTable:newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage:parseInt(event.target.value, 10),
            currentPage:1,
            loading: true,
        },() => {
            this.__fetchData(false);
        });
    };


    render() {
        return (
            <div className="row"> 
                <div className="col-md-6 col-lg-7">
                    <Paper className={this.state.showDetail ? "show" : "hide"}>
                        <h3>Transponder Price</h3>
                        <form name="update" id="update" noValidate>
                            <div className="form-group row">
                                <label htmlFor="inputPassword3" className="col-sm-3 col-md-6 col-lg-3 col-form-label pr-sm-0">Price:</label>
                                <div className="col-sm-6 pl-sm-0">
                                    {
                                        this.state.showEdit ?
                                            (
                                                <div className="form-group">
                                                <TextField
                                                    type='text'
                                                    id='price'
                                                    name="price"
                                                    label="price"
                                                    onChange={(e) => this.handleChange(e, 'price')}
                                                    minLength={4}
                                                    maxLength={50}
                                                    value={this.state.price}
                                                    fullWidth
                                                />
                                                {validator.message('price', this.state.price, 'price|required')}
                                                <div className='text-danger'>{this.state.errors.price}</div>
                                            </div>
                                            ) :
                                            (
                                                <p className="text-capitalize med mb-0">{this.state.price}</p>
                                            )
                                    }

                                </div>
                            </div>
                            <fieldset className="form-group">
                                <div className="row">
                                    <legend className="col-form-label col-sm-3 col-md-6 col-lg-3  pt-sm-0">Discount:</legend>
                                    <div className="col-sm-6 pl-sm-0">
                                        {
                                            this.state.showEdit ?
                                                (
                                                    <div className="form-group">
                                                    <TextField
                                                        type='text'
                                                        id='discountFullRegister'
                                                        name="discountFullRegister"
                                                        label="discountFullRegister"
                                                        onChange={(e) => this.handleChange(e, 'discountFullRegister')}
                                                        minLength={4}
                                                        maxLength={50}
                                                        value={this.state.discountFullRegister}
                                                        fullWidth
                                                    />
                                                    {validator.message('discountFullRegister', this.state.discountFullRegister, 'discountFullRegister|required')}
                                                    <div className='text-danger'>{this.state.errors.discountFullRegister}</div>
                                                </div>
                                                ) :
                                                (
                                                    <p className="text-capitalize med mb-0">{this.state.discountFullRegister}</p>
                                                )
                                        }
                                    </div>
                                </div>
                            </fieldset>
                            {
                                this.state.showEdit ?
                                    (
                                        <div className="row justify-content-between">
                                            <div className="col">
                                                <button className="btn btn-blue-trans" onClick={this.handleCloseEdit}>Cancel</button>
                                            </div>
                                            <div className="col text-right">
                                                <button
                                                    className="btn btn-blue-trans"
                                                    onClick={this.handleSubmit}
                                                    disabled={this.state.loadingButton && 'disabled' }>
                                                    Save Changes { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }</button>
                                            </div>
                                        </div>
                                    ) :
                                    (
                                        <button className="btn btn-blue-trans" onClick={this.handleShowEdit}>Edit</button>
                                    )
                            }

                        </form>
                    </Paper>

                    <Paper className={this.state.showForm ? "show" : "hide"}>
                        <form name="edit" id="edit" className="row justify-content-between" noValidate>
                            <div className="col-lg-7">
                                <div className="form-group">
                                    <TextField
                                        type='text'
                                        id='price'
                                        name="price"
                                        label="Price"
                                        onChange={(e) => this.handleChange(e, 'price')}
                                        minLength={4}
                                        maxLength={50}
                                        value={this.state.price}
                                        fullWidth
                                    />
                                    {validator.message('price', this.state.price, 'required')}
                                    <div className='text-danger'>{this.state.errors.price}</div>
                                </div>

                                <div className="form-group">
                                    <TextField
                                        type='text'
                                        id='discountFullRegister'
                                        name="discountFullRegister"
                                        label="discountFullRegister"
                                        onChange={(e) => this.handleChange(e, 'discountFullRegister')}
                                        minLength={4}
                                        maxLength={50}
                                        value={this.state.discountFullRegister}
                                        fullWidth
                                    />
                                    {validator.message('discountFullRegister', this.state.discountFullRegister, 'discountFullRegister|required')}
                                    <div className='text-danger'>{this.state.errors.discountFullRegister}</div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-4">
                                <button
                                    onClick={this.handleSubmit}
                                    className="btn btn-blue-trans full"
                                    disabled={this.state.loadingButton && 'disabled' }>
                                    Submit { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                </button>
                            </div>
                        </form>
                    </Paper>
                </div>
                <ToastContainer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        permission_state: state.permission_state,
    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(Transponder);