import React, { Component } from "react";
import Api from "../../../libraries/api";
import ProductConfigCheck from "./ProductConfigCheck";

export default class ProductConfigList extends Component {
  constructor(props) {
    super(props);
    this.productConfigUrl = "/products-config/";
    this.state = {
      products: [],
      productStatus: "1",
    };
    Api.get(this.productConfigUrl, [], false).then((resp) => {
      this.setState({ products: resp["data"]["productsConfig"] });
    });
  }
  render() {
    return (
      <div>
        {this.state.products.map(function (item, i) {
          return (
            <div class="row">
              <div class="col-md-2">{item.product}</div>
              <div class="col-md-1">
                <ProductConfigCheck itemId={item.id} isChecked={item.status} />
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
